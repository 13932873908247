import * as React from 'react';
import { SortingSelector as SKSortingSelector } from 'searchkit/es';
import styled from 'styled-components';
import { FlexContainer } from '../../../components/flexContainer';
import { media } from '../../../helpers';

const Container = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;

  ${media.lg(`
    flex-direction: row;
    align-self: center;
  `)}
`;

const Label = styled.span`
  margin-right: 5px;
  align-self: flex-start;
  font-size: 13px;

  ${media.lg(`
    align-self: center;
  `)}
`;

export const SortingSelector = () => (
  <Container>
    <Label>Sorteren op:</Label>
    <SKSortingSelector
      options={[
        {
          label: 'Prijs laag naar hoog',
          field: 'price',
          order: 'asc',
          defaultOption: true
        },
        {
          label: 'Prijs hoog naar laag',
          field: 'price',
          order: 'desc'
        }
      ]}
    />
  </Container>
);

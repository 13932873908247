import React from 'react';
import ReactDOM from 'react-dom';
import { Comparer } from './comparer';
import { parseWidgetProps } from '../../helpers';

interface IWidgetProps {
  productGroup: string;
}

const WIDGET_CLASSNAME = '.comparer-widget';

(() => {
  const comparerEl = document.querySelector(WIDGET_CLASSNAME);
  if (!comparerEl) {
    return;
  }

  const widgetProps = parseWidgetProps<IWidgetProps>(comparerEl);
  if (!widgetProps.productGroup) {
    throw Error('No product group specified!');
  }

  ReactDOM.render(
    <Comparer
      containerEl={comparerEl.parentElement as HTMLElement}
      productGroup={widgetProps.productGroup}
    />,
    comparerEl
  );
})();

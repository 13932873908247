import styled, { css } from 'styled-components';
import { media } from '../../helpers';

interface IProps {
  active: boolean;
}

export const PaginationContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;

  ${media.md(`
    justify-content: flex-start;
  `)};
`;

export const Page = styled.button`
  padding: 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  user-select: none;
  background-color: white;
  font-size: 13px;
  height: 100%;
  min-width: 32px;
  margin: 0 2px;

  ${({ active }: IProps) =>
    active &&
    css`
      background-color: #4a90e2;
      color: white;
    `};

  &:first-child,
  &:last-child {
    flex-shrink: 0;
  }
`;

export const Ellipsis = styled.span`
  font-size: 12px;
  margin: 0 2px;
  display: flex;
  align-items: center;
`;

import React from 'react';
import { Hits, HitsProps } from 'searchkit/es';
import { colors } from '../../maps';
import { ComparerProduct } from '../../components/comparerProduct';

interface IProps extends HitsProps {
  result: {
    _source: {
      modelType: string;
      price: number; // This inteface for now does not containe a shopRedirectUrl or a shopImage. If it will we can use one interface for two product components and just import this.
      storageSize: number;
      affiliateUrl: string;
      shopName: string;
      color: string;
      deliveryTime: string;
    };
  };
}

export const ComparerHitComponent = (props: IProps) => {
  const {
    modelType,
    price,
    storageSize,
    affiliateUrl,
    shopName,
    color,
    deliveryTime
  } = props.result._source;

  return (
    <ComparerProduct
      name={modelType}
      price={price}
      deliveryTime={deliveryTime}
      storage={storageSize}
      shopName={shopName}
      productRedirectUrl={affiliateUrl}
      color={colors[color]}
      colorName={color}
    />
  );
};

export const ComparerProductHits = () => (
  <Hits
    hitsPerPage={10}
    scrollTo={false}
    itemComponent={ComparerHitComponent}
  />
);

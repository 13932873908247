import React from 'react';
import { Pagination as SKPagination } from 'searchkit/es';
import { PaginationContainer, Page, Ellipsis } from './components';

interface IPaginationItem {
  key: number;
  active: boolean;
  page: number;
  label: string;
}

export class Pagination extends SKPagination {
  constructor(props: any) {
    super(props);

    this.translations = {
      'pagination.previous': '« Vorige',
      'pagination.next': 'Volgende »'
    };
  }

  static defaultProps = {
    ...SKPagination.defaultProps,
    showNumbers: true
  };

  PAGES_TILL_END = 4;

  appendLastPage = (
    lastPage: IPaginationItem,
    currentPage: number,
    pages: IPaginationItem[]
  ) => {
    const copy = [...pages];

    if (lastPage.page - currentPage > this.PAGES_TILL_END) {
      copy.splice(pages.length - 1, 0, lastPage);
    }

    if (lastPage.page - currentPage <= this.PAGES_TILL_END) {
      copy.splice(pages.length - 2, 1, lastPage);
    }

    return copy;
  };

  render(): any {
    if (!this.accessor) {
      return null;
    }

    if (!this.hasHits()) {
      return null;
    }

    const pages = this.getPages();
    const totalPages = this.getTotalPages();
    const currentPage = this.getCurrentPage();

    const lastPage = {
      key: totalPages,
      label: totalPages.toString(),
      page: totalPages,
      active: false
    };

    const splicedPages = this.appendLastPage(lastPage, currentPage, pages);

    if (totalPages === 1) {
      return null;
    }

    return (
      <PaginationContainer>
        {splicedPages.map(
          ({ active, page, label }: IPaginationItem, index: number) => {
            if (label === '...') {
              return <Ellipsis key={index + label}>&#8230;</Ellipsis>;
            }
            return (
              <Page
                key={label}
                active={active}
                onClick={() => this.setPage(page)}
              >
                {label}
              </Page>
            );
          }
        )}
      </PaginationContainer>
    );
  }
}

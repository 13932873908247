import * as React from 'react';
import {
  ImmutableQuery,
  InitialLoader,
  Layout,
  LayoutBody,
  SearchkitManager,
  SearchkitProvider,
  SideBar,
  TermQuery
} from 'searchkit/es';
import {
  CheckboxColorFilter,
  CheckboxShopFilter,
  CheckboxStorageFilter,
  CheckboxModelFilter,
  PriceRangeFilter,
  ClearFilter
} from '../../searchkit/filters';
import { ComparerProductHits } from '../../searchkit/comparerProductHits';
import { Pagination } from '../../searchkit/pagination';
import {
  ActionBar,
  ComparerContainer,
  FacetContainer,
  FilterButton,
  LayoutResults,
  SortingSelector,
  SelectedFiltersContainer,
  SelectedFilters
} from './components';
import { ComparerProductsPlaceholder } from '../../components/comparerWidgetPlaceholders';
import { NoHits } from '../../searchkit/noHits';
import { ThemeProvider } from 'styled-components';
import { useGetContainerWidth } from '../../hooks';
import { breakpoints } from '../../helpers';

const getSearchKitManager = (productGroup: string): SearchkitManager => {
  const sk = new SearchkitManager(
    process.env.REACT_APP_ELASTIC_SEARCH_URL || '',
    {
      useHistory: false,
      searchUrlPath: 'search'
    }
  );
  sk.addDefaultQuery((query: ImmutableQuery) =>
    query.addQuery(TermQuery('productGroup', productGroup))
  );
  return sk;
};

interface IProps {
  productGroup: string;
  containerEl: HTMLElement;
}

export const Comparer = ({ containerEl, productGroup }: IProps) => {
  const [showFilters, setShowFilters] = React.useState(false);
  const sk = getSearchKitManager(productGroup);
  const width = useGetContainerWidth(containerEl);

  return (
    <ThemeProvider
      theme={{
        containerWidth: width
      }}
    >
      <SearchkitProvider searchkit={sk}>
        <Layout>
          <LayoutBody>
            <ComparerContainer showFilters={showFilters}>
              <FilterButton onClick={() => setShowFilters(!showFilters)}>
                {showFilters ? 'Verberg filters' : 'Toon filters'}
              </FilterButton>
              <SideBar>
                <FacetContainer>
                  <CheckboxModelFilter />
                </FacetContainer>
                <FacetContainer>
                  <CheckboxColorFilter />
                </FacetContainer>
                <FacetContainer>
                  <CheckboxStorageFilter />
                </FacetContainer>
                <FacetContainer>
                  <PriceRangeFilter />
                </FacetContainer>
                <FacetContainer>
                  <CheckboxShopFilter />
                </FacetContainer>
              </SideBar>
              <LayoutResults>
                <ActionBar>
                  <SelectedFiltersContainer>
                    <SelectedFilters />
                    <SortingSelector />
                  </SelectedFiltersContainer>
                  <ClearFilter />
                </ActionBar>
                <ComparerProductHits />
                <NoHits />
                <InitialLoader component={ComparerProductsPlaceholder} />
                <Pagination
                  pageScope={width && width >= breakpoints.md ? 3 : 0}
                />
              </LayoutResults>
            </ComparerContainer>
          </LayoutBody>
        </Layout>
      </SearchkitProvider>
    </ThemeProvider>
  );
};

import styled from 'styled-components';
import { LayoutResults as Layout } from 'searchkit/es';
import { Button } from '../../../components';
import { FlexContainer } from '../../../components';
import { media } from '../../../helpers';

export const FacetContainer = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
`;

interface IProps {
  showFilters?: boolean;
}

export const ComparerContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-between;

  .sk-layout__filters {
    min-width: 180px;
    display: ${({ showFilters }: IProps) => (showFilters ? 'block' : 'none')};
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 15px;
  }

  .sk-panel__header {
    margin-bottom: 10px;
  }

  ${media.sm(`
    flex-direction: row;
    
    .sk-layout__filters {
      margin-right: 10px;
      display: table;
    }
  `)}
`;

export const FilterButton = styled(Button)`
  margin-bottom: 10px;

  ${media.sm(`
    display: none;
  `)}
`;

export const ActionBar = styled(FlexContainer)`
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  ${media.md(`
    justify-content: space-between;
  `)}
`;

export const LayoutResults = styled(Layout)`
  flex: 1;
`;

export const SelectedFiltersContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  .sk-selected-filters {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    > * {
      margin: 2.5px 2.5px 2.5px 0;
    }
  }

  ${media.md(`
    margin: 0;
  `)}

  ${media.sm(`
    flex-direction: row;
  `)}
`;

import * as React from 'react';
import { SelectedFilters as SKSelectedFilters } from 'searchkit/es';
import { FlexContainer, SelectedFilter } from '../../../components';
import styled from 'styled-components';
import { media } from '../../../helpers';

const Container = styled(FlexContainer)`
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
  align-items: center;
`;

const Label = styled.span`
  font-size: 13px;
  margin-right: 10px;
  display: block;
  width: 100%;
  margin-top: 10px;

  ${media.lg(`
    display: inline;
    width: auto;
  `)}
  ${media.sm(`
    margin-top: 0;
  `)}
`;

export class SelectedFilters extends SKSelectedFilters {
  render(): any {
    if (!this.hasFilters()) {
      return <div />;
    }

    const filters = this.getFilters();
    return (
      <Container>
        <Label>Geselecteerde filters: </Label>
        {filters.map((filter, index) => (
          <SelectedFilter
            key={index}
            removeFilter={() => this.removeFilter(filter)}
            labelValue={filter.value}
            filterId={filter.id}
          />
        ))}
      </Container>
    );
  }
}
